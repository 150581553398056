import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import StorageProvider from "../../../framework/src/StorageProvider";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  seletedItem: string;
  stepNumber: number;
  backBtn: ()=>void;
  goToSuccess: (msg?:string)=>void;
  // Customizable Area End
}

interface S {
// Customizable Area Start
description: string;
stockQunatity: string;
serialNo: string
revisionDate:string;
toolsInfoData: {
  brand_id: string;
  brand_name: string;
  product_name: string;
  image_data: any[];
  color:any;
  removedImgs:number[]
};
machineCost: string;
error:{
  stockQunatity: string;
},
errorSerialNo: string;
imageData: File[];
isEditing: boolean;
catalogueId: string;
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class ToolsAdditionalInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userSessionData: any;
  userToken: {meta:{token:string}};
  addInventoryItemApiCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      description: '',
      stockQunatity: '',
      serialNo: "",
      revisionDate:"",
      toolsInfoData: {
        brand_id: '',
        brand_name: '',
        product_name: '',
        image_data: [],
        color:null,
        removedImgs:[]
      },
      machineCost: '',
      error:{
        stockQunatity: '',
      },
      errorSerialNo: '',
      imageData:[],
      isEditing: false,
      catalogueId: ""
      // Customizable Area End
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData); 
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId === this.addInventoryItemApiCallId){
      if(responseJson){  
        if (responseJson.data.attributes.errors && responseJson.data.attributes.errors.name) {
          const nameErrors = responseJson.data.attributes.errors.name;
          const nameErrormsg = "Name has already been taken"
          if (nameErrors.includes("has already been taken")) {
            this.props.goToSuccess(nameErrormsg);
          }          
        }
        else{      
          this.props.goToSuccess()
        }
      }
    }
    // Customizable Area End
  }

  handleDescription=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({description: event.target.value})
  }
  handleOnChange=(event:React.ChangeEvent<HTMLInputElement>)=>{
    const value = event.target.value;
    const name = event.target.name as keyof S;
    let patternRegex: RegExp;

    if (["machineCost",].includes(name)) {
      patternRegex =/^([1-9]\d*|0)(\.\d*)?$/;
    } else {
      patternRegex =/^(0|[1-9]\d*)$/;
    }
  
    const isValidFeild = patternRegex.test(value);
    if (isValidFeild || value === '') {
      this.setState({ [name]: value } as unknown as Pick<S, keyof S>);
    }
  }
  handleSerialNo=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({
      serialNo: event.target.value,
      errorSerialNo: ''
    })
  }

  handleDate=(date: any)=>{
    this.setState({revisionDate: date.format("YYYY-MM-DD")})
  }


  addItem = (cateId:number, subCatId:number) => {
    const headers = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addInventoryItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    if(this.state.catalogueId != ""){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addItemAPIEndPoint + `/${this.state.catalogueId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.editApiMethod
      );
    }
    else{

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addItemAPIEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );

    }
    

    let formdata = new FormData();
    formdata.append("category_static_id",cateId.toString());
    formdata.append("sub_category_static_id",subCatId.toString());
    formdata.append("name",this.state.toolsInfoData?.product_name); 
    if(this.state.toolsInfoData?.brand_id){
      formdata.append("brand_id",this.state.toolsInfoData?.brand_id); 
      // formdata.append("brand_attributes[name]",this.state.toolsInfoData?.brand_name);
    }
    formdata.append("stock_qty",this.state.stockQunatity);
    formdata.append("meta_data[description]",this.state.description);
    formdata.append("meta_data[color]",this.state.toolsInfoData.color?.title ?? "");
    formdata.append("meta_data[serial_no]",this.state.serialNo);
    if(this.props.seletedItem === configJSON.machineText){
      formdata.append("meta_data[hourly_cost]",this.state.machineCost);
    }
    formdata.append("meta_data[revision_date]",this.state.revisionDate);
    if(this.state.imageData.length > 0){
      this.state.imageData.forEach((fileImg,index:number) => {
        this.state.catalogueId != "" ?formdata.append(`images[${index}][data]`,fileImg) : formdata.append(`images[]`,fileImg) ;
      });
    }
    if(this.state.toolsInfoData.removedImgs.length > 0){
      this.state.toolsInfoData.removedImgs.forEach((id:number,index:number) => {
        formdata.append(`images[${index+this.state.imageData.length}][remove]`,String(true))
        formdata.append(`images[${index+this.state.imageData.length}][id]`,String(id))
      });
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createCatalogueItems=()=> {
    const { seletedItem } = this.props;
    switch (seletedItem){
      case configJSON.hardwareText:
        this.addItem(configJSON.ToolsStaticId,configJSON.HardwareStaticId);
        break;
      case configJSON.machineText:
        this.addItem(configJSON.ToolsStaticId,configJSON.MachineryStaticId);
        break;
    }
  }

   readAttachments= () => {
    const allImagesUrl = this.state.toolsInfoData.image_data;

    allImagesUrl.forEach(img => {
      fetch(img.url)
      .then(response => response.blob())
      .then(blob => {
        const imgFile = new File([blob], img.name, { type:img.type })
        this.setState((prevState)=>({imageData:[...prevState.imageData,imgFile]}))
      })
      .catch(error => {
      
      });
      
    });
  }
  async componentDidMount(): Promise<void> {
    const toolsItemdata = await StorageProvider.get("toolsdata");
    const toolsAdditionalData = await StorageProvider.get("toolsAdditionalData");
    const tools = await JSON.parse(toolsItemdata)
    const toolsAdditional = await JSON.parse(toolsAdditionalData)
    this.setState({
      toolsInfoData : tools,
      description: toolsAdditional?.description ?? "",
      stockQunatity: toolsAdditional?.stockQuantity ?? "",
      serialNo: toolsAdditional?.serialNo ?? "",
      revisionDate:toolsAdditional?.revisionDate ?? "",
      isEditing : toolsAdditional?.isEditing ?? false,
      catalogueId: toolsAdditional?.itemId ?? "",
      machineCost:toolsAdditional?.machineCost ?? "",

    },this.readAttachments)
    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }

  goBackToPreviousStep = () => {
    const toolsAdditionalData = {
      "description": this.state.description,
      "stockQuantity": this.state.stockQunatity,
      "serialNo": this.state.serialNo,
      "revisionDate":this.state.revisionDate,
      "isEditing":this.state.isEditing,
      "itemId":this.state.catalogueId,
      "machineCost":this.state.machineCost,
    }
    StorageProvider.set("toolsAdditionalData", JSON.stringify(toolsAdditionalData));
    this.props.backBtn();
  }
  // Customizable Area End
}
