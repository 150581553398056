import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import {PriceData, TrophyData, CertifcateData} from "./InventoryTypes";
import i18n from "../../../web/src/utilities/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  classes?: any;
  seletedItem: string;
  stepNumber: number;
  backBtn: ()=>void;
  goToSuccess: (msg?:string)=>void;
  // Customizable Area End
}

interface S {
// Customizable Area Start
allergens:string;
tastingData: any;
locationData:{
  appellation: string;
  appellation_type: string;
  country: string;
  region: string;
  subregion: string;
  era: string;
  origin_region: string;
  style: string;
};
basicInfoData:{
  product_name: string;
  producer: string;
  brand: string;
  grape_variety:string;
  grape_variety_per: string;
  complement: string;
  color: string;
  dosage: string; 
  quantity: string;
  raw_material: string;
  raw_material_per: string;
  item_image:{
    name: string;
    type: string;
    url: string;
  }[];
  age: string;
  vintage:string;
  style:string;
  category:string;
  removedImages:number[]
};
itemDetailsData: {
  description: string;
  aging: string;
  volume: string;
  alcohol:string;
  production: string;
  agingProcess: string;
  sku: string;
  gtinNumber: string; 
  ibu:string; 
  srm:string; 
  og:string; 
  fg:string; 
  ebc:string; 
  trophyData:TrophyData[];
  priceData : PriceData[];
  otherProComment:string;
  deletedPrices:number[];
  certificates:CertifcateData[];
  removedCertificates:number[];
};
moreDetailsData: {
  hop: string;
  mashing: string;
  strength: string;
  fermentation:string;
  ingredients: string;
},

isLoading: boolean;
trophyImages:{index:number,img:File}[];
isEditing:boolean;
catalogueId:string;
productImages: File[];
// Customizable Area End
}
  
interface SS {
id: any;
// Customizable Area Start
// Customizable Area End
}
  
// Customizable Area Start

// Customizable Area End
export default class AllergensController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addItemApiCallId:string = "";
  userSessionData: any;
  userToken: {meta:{token:string}};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      // Customizable Area Start
      allergens:'',
      tastingData: {},
      locationData: {
        appellation: '',
        appellation_type: '',
        country: '',
        region: '',
        subregion: '',
        era: '',
        origin_region: '',
        style: '',
      },
      basicInfoData: {
        product_name: '',
        producer: '',
        brand: '',
        grape_variety:'',
        grape_variety_per: '',
        complement: '',
        color: '',
        dosage: '', 
        quantity: '',
        raw_material: '',
        raw_material_per: '',
        item_image: [{
          name: '',
          type: '',
          url: '',
        }],
        age: '',
        vintage:'',
        style:'',
        category:'',
        removedImages:[]
      },
      itemDetailsData: {
        description: '',
        aging: '',
        volume: '',
        alcohol:'',
        production: '',
        agingProcess: '',
        sku: '',
        gtinNumber: '', 
        ibu:'',
        srm:'',
        og:'',
        fg:'',
        ebc:'',
        trophyData: [],
        priceData :[],
        otherProComment:'',
        deletedPrices:[],
        certificates:[],
        removedCertificates:[]
      },
      moreDetailsData: {
        hop: '',
        mashing: '',
        strength: '',
        fermentation:'',
        ingredients: '',
      },

      isLoading:false,
      trophyImages:[],
      isEditing:false,
      catalogueId:"",
      productImages:[]
      // Customizable Area End
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId && responseJson){
      if (apiRequestCallId==this.addItemApiCallId){
          if(responseJson.data.attributes.errors && responseJson.data.attributes.errors.name){  
            
              const nameErrors = responseJson.data.attributes.errors.name;
              const nameErrormsg = "Name has already been taken"
              if (nameErrors.includes("has already been taken")) {
                this.props.goToSuccess(nameErrormsg);
              }  
          } else{      
            this.props.goToSuccess()
          }    
      }
      else{
        this.setState({isLoading:false})
      }
    }

    // Customizable Area End
  }

  handleAllergens=(event:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({allergens: event.target.value})
  }

  createBeerItem =(categoryId:number, subCategoryId:number)=>{
    let formdata = new FormData();
    formdata.append("meta_data[raw_material]", this.state.basicInfoData.raw_material)
    formdata.append("meta_data[raw_material_percent]",this.state.basicInfoData.raw_material_per);
    formdata.append("meta_data[brand]",this.state.basicInfoData.brand);
    formdata.append("location_attributes[style]", this.state.locationData.style);

    formdata.append("meta_data[ibu]",this.state.itemDetailsData.ibu);
    formdata.append("meta_data[srm]",this.state.itemDetailsData.srm);
    formdata.append("meta_data[og]",this.state.itemDetailsData.og);
    formdata.append("meta_data[fg]",this.state.itemDetailsData.fg);
    formdata.append("meta_data[ebc]",this.state.itemDetailsData.ebc);

    formdata.append("meta_data[hop]",this.state.moreDetailsData.hop);
    formdata.append("meta_data[mashing]",this.state.moreDetailsData.mashing);
    formdata.append("meta_data[strength]",this.state.moreDetailsData.strength);
    formdata.append("meta_data[fermentation]",this.state.moreDetailsData.fermentation);
    formdata.append("meta_data[ingredients]",this.state.moreDetailsData.ingredients);
    this.addItemApiCall(formdata,categoryId,subCategoryId);
  }

  createSpiritItem =(categoryId:number, subCategoryId:number)=>{
    let formdata = new FormData();
    formdata.append("meta_data[raw_material]", this.state.basicInfoData.raw_material)
    formdata.append("meta_data[raw_material_percent]",this.state.basicInfoData.raw_material_per);
    formdata.append("meta_data[brand]",this.state.basicInfoData.brand);
    formdata.append("meta_data[style]", this.state.basicInfoData.style)
    formdata.append("meta_data[category]", this.state.basicInfoData.category)
    formdata.append("location_attributes[appellation]", this.state.locationData.appellation);
    formdata.append("location_attributes[appellation_type]",this.state.locationData.appellation_type);
    formdata.append("location_attributes[sub_region]", this.state.locationData.subregion);
    this.addItemApiCall(formdata,categoryId,subCategoryId);
  }

  createOtherItem =(categoryId:number, subCategoryId:number)=>{
    let formdata = new FormData();
    formdata.append("meta_data[raw_material]", this.state.basicInfoData.raw_material)
    formdata.append("meta_data[comment]", this.state.itemDetailsData.otherProComment)
    formdata.append("location_attributes[appellation]", this.state.locationData.appellation);
    formdata.append("location_attributes[appellation_type]",this.state.locationData.appellation_type);
    formdata.append("location_attributes[sub_region]", this.state.locationData.subregion);
    formdata.append("location_attributes[region_of_origin]",this.state.locationData.origin_region);
    formdata.append("meta_data[brand]",this.state.basicInfoData.brand);
    formdata.append("meta_data[raw_material_percent]",this.state.basicInfoData.raw_material_per);
    this.addItemApiCall(formdata,categoryId,subCategoryId);
  }

  createWineItem=(categoryId:number, subCategoryId:number)=> {  
    let formdata = new FormData();
    formdata.append("location_attributes[sub_region]", this.state.locationData.subregion);
    formdata.append("location_attributes[appellation]", this.state.locationData.appellation);
    formdata.append("location_attributes[appellation_type]",this.state.locationData.appellation_type);
    formdata.append("meta_data[brand]",this.state.basicInfoData.brand);
    formdata.append("meta_data[grape_variety]",this.state.basicInfoData.grape_variety);
    formdata.append("meta_data[grape_variety_percent]",this.state.basicInfoData.grape_variety_per);
    this.addItemApiCall(formdata,categoryId,subCategoryId);
  };

  addItemApiCall = (formdata: FormData,categoryId:number,subCategoryId:number) => {

    const headers = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addItemApiCallId = requestMessage.messageId;

    if(this.state.catalogueId != ""){
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addItemAPIEndPoint + `/${this.state.catalogueId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.editApiMethod
      );
    }
    else{
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addItemAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    formdata.append("category_static_id",categoryId.toString());
    formdata.append("sub_category_static_id",subCategoryId.toString()); 
    formdata.append("name",this.state.basicInfoData.product_name); 
    formdata.append("meta_data[color]",this.state.basicInfoData.color);
    formdata.append("location_attributes[country]", this.state.locationData.country);
    formdata.append("location_attributes[region]", this.state.locationData.region);
    formdata.append("meta_data[producer]",this.state.basicInfoData.producer);
    if(this.state.basicInfoData.age!==undefined &&this.state.basicInfoData.age!=="" ){
      formdata.append("meta_data[age]",this.state.basicInfoData.age); 
    }
    if(this.state.basicInfoData.vintage!==undefined &&this.state.basicInfoData.vintage!=="" ){
      formdata.append("meta_data[vintage]",this.state.basicInfoData.vintage); 
    }
    formdata.append("meta_data[quantity]",this.state.basicInfoData.quantity);
    formdata.append("stock_qty",this.state.basicInfoData.quantity);
    formdata.append("meta_data[name_complement]",this.state.basicInfoData.complement);
    formdata.append("meta_data[short_description]",this.state.itemDetailsData.description);
    formdata.append("meta_data[aging_potential]",this.state.itemDetailsData.aging);
    formdata.append("meta_data[container_with_volume]",this.state.itemDetailsData.volume);
    formdata.append("meta_data[alcohol]",this.state.itemDetailsData.alcohol); 
    formdata.append("meta_data[dosage]",this.state.basicInfoData.dosage);
    formdata.append("meta_data[production_process]",this.state.itemDetailsData.production);
    formdata.append("meta_data[aging_process]",this.state.itemDetailsData.agingProcess);
    formdata.append("meta_data[sku]",this.state.itemDetailsData.sku);
    formdata.append("meta_data[gtin]",this.state.itemDetailsData.gtinNumber);

  if(this.state.tastingData){
    formdata.append("tasting_notes[comment]",this.state.tastingData.comment);
    formdata.append("tasting_notes[temperature]",this.state.tastingData.temperature);
    formdata.append("tasting_notes[opening_time]",this.state.tastingData.openingTime);
    formdata.append("tasting_notes[eye]",this.state.tastingData.eye);
    formdata.append("tasting_notes[nose]",this.state.tastingData.nose);
    formdata.append("tasting_notes[main_aromas]",this.state.tastingData.mainAroma);
    formdata.append("tasting_notes[flavours]",this.state.tastingData.flavours);
    formdata.append("tasting_notes[mouth]",this.state.tastingData.mouth);
    formdata.append("tasting_notes[food_pairing]",this.state.tastingData.foodPairing);
  }

  if(this.state.productImages.length > 0){
    this.state.productImages.forEach((fileImg,index:number) => {
      this.state.catalogueId != "" ? formdata.append(`images[${index}][data]`,fileImg) : formdata.append(`images[]`,fileImg);
    });
  }
  if(this.state.basicInfoData.removedImages.length > 0){
    this.state.basicInfoData.removedImages.forEach((id:number,index:number) => {
      formdata.append(`images[${index+this.state.productImages.length}][id]`,String(id))
      formdata.append(`images[${index+this.state.productImages.length}][remove]`,String(true))
    });
  }

  this.trophyPriceAdd(formdata)
  this.certificateAdd(formdata)
  
    formdata.append("meta_data[allergens]",this.state.allergens);
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({isLoading:true})
  }

  trophyPriceAdd=(formdata:FormData)=>{
    if (this.state.itemDetailsData.trophyData && this.state.itemDetailsData.trophyData.length > 0) {
      this.state.itemDetailsData.trophyData.forEach((trophyData, index) => {
        if(trophyData.trophyId){
          formdata.append(`trophies_attributes[${index}][id]`, String(trophyData?.trophyId));
        }
        formdata.append(`trophies_attributes[${index}][name]`, trophyData?.trophyName);
        formdata.append(`trophies_attributes[${index}][year]`, String(trophyData?.trophyYear));
        formdata.append(`trophies_attributes[${index}][result]`, trophyData?.trophyResult);
        formdata.append(`trophies_attributes[${index}][comment]`, trophyData?.trophyComment);
        // if(trophyData?.trophyImages?.length ?? 0>index){
        //   formdata.append(`trophies_attributes[${index}][image]`, trophyData.trophyImages?[index].url);
        // }
        const img = this.state.trophyImages.find(x=>x.index == index)
        if(img){
          formdata.append(`trophies_attributes[${index}][image]`, img.img);
        }
      });
    }
    let curIndex=0;
    if (this.state.itemDetailsData.priceData && this.state.itemDetailsData.priceData.length > 0) {
      this.state.itemDetailsData.priceData.forEach((priceData, index) => {
        if(priceData.id){
          formdata.append(`prices_attributes[${index}][id]`, priceData.id);
        }
        formdata.append(`prices_attributes[${index}][title]`, priceData.priceLabel);
        formdata.append(`prices_attributes[${index}][amount]`, String(priceData.itemPrice));
        formdata.append(`prices_attributes[${index}][tax_included]`, String(priceData.isTaxIncluded));
        curIndex +=1;
      });
    }
    
    if (this.state.itemDetailsData.deletedPrices && this.state.itemDetailsData.deletedPrices.length > 0) {
      this.state.itemDetailsData.deletedPrices.forEach((priceId, index) => {
        formdata.append(`prices_attributes[${index+curIndex}][id]`, priceId.toString());
        formdata.append(`prices_attributes[${index+curIndex}][_destroy]`,"true");
      });
    }
  
  }

  certificateAdd = (formdata:FormData) => {
    let curIndex = 0;
    if (this.state.itemDetailsData.certificates && this.state.itemDetailsData.certificates.length > 0) {
      this.state.itemDetailsData.certificates.forEach((certificate, index) => {  
        if(!certificate.certification_assignment_id){
          formdata.append(`catalogue_certification_assignments_attributes[${index}][certification_id]`, certificate.id.toString());
          curIndex+=1;
        }     
      });
    }
    if (this.state.itemDetailsData.removedCertificates && this.state.itemDetailsData.removedCertificates.length > 0) {
      this.state.itemDetailsData.removedCertificates.forEach((certificate, index) => { 
        formdata.append(`catalogue_certification_assignments_attributes[${index+curIndex}][id]`, certificate.toString()); 
        formdata.append(`catalogue_certification_assignments_attributes[${index+curIndex}][_destroy]`, "true"); 
      });
    }
  }

  createCatalogueItems =()=> {
    switch(this.props.seletedItem){
      case configJSON.wineText:
        this.createWineItem(configJSON.ProductsStaticId,configJSON.WineStaticId);
        break;
      case configJSON.beerText:
        this.createBeerItem(configJSON.ProductsStaticId,configJSON.BeerStaticId);
        break;
      case configJSON.spiritText:
        this.createSpiritItem(configJSON.ProductsStaticId,configJSON.SpritStaticId);
        break;
      case configJSON.otherText:
        this.createOtherItem(configJSON.ProductsStaticId,configJSON.OtherProductStaticId);
      break;
    }
  }


  backOneStep = () => {
    const allergens = {
      "allergens":this.state.allergens,
      "isEditing":this.state.isEditing,
      "itemId":this.state.catalogueId,
    }    
    StorageProvider.set("allergensdata",JSON.stringify(allergens))
    this.props.backBtn();
  }

  readImageProduct= () => {
    const allImagesUrl = this.state.basicInfoData.item_image;

    allImagesUrl.forEach(img => {
      fetch(img.url)
      .then(response => response.blob())
      .then(blob => {
        const imgFile = new File([blob], img.name, { type:img.type })
        this.setState((prevState)=>({productImages:[...prevState.productImages,imgFile]}))
      })
      .catch(error => {
      
      });
      
    });

    
    this.state.itemDetailsData.trophyData.forEach((trophy,trophIndex)=>{
      if(trophy.trophyImages && trophy.trophyImages.length>0){
        const img = trophy.trophyImages[0]
        fetch(img.url)
        .then(response => response.blob())
        .then(blob => {
          const imgFile = new File([blob], img.name, { type:img.type })
          this.setState((prevState)=>({trophyImages:[...prevState.trophyImages,{
            index:trophIndex,
            img:imgFile
          }]}))
        })
        .catch(error => {
        
        });
      }
    })
  }

  async componentDidMount(): Promise<void> {
    const testingdata = await StorageProvider.get("tastingdata");
    const locations = await StorageProvider.get("itemLocationdata");
    const itemInfo = await StorageProvider.get("itemInfodata");
    const detailsData = await StorageProvider.get("itemDetailsdata");
    const moreDetailsData = await StorageProvider.get("moreDetailsdata");
    const allergensData = await StorageProvider.get("allergensdata");

    const testing = await JSON.parse(testingdata);
    const locationsData = await JSON.parse(locations);
    const itemInfoData = await JSON.parse(itemInfo);
    const itemdetailsData = await JSON.parse(detailsData);
    const moreDetails = moreDetailsData?await JSON.parse(moreDetailsData):this.state.moreDetailsData;
    const allergens = await JSON.parse(allergensData);

    this.setState({
      tastingData: testing,
      locationData: locationsData,
      basicInfoData: itemInfoData,
      itemDetailsData:itemdetailsData,
      moreDetailsData:moreDetails,
      allergens:allergens?.allergens ?? "",
      isEditing : allergens?.isEditing ?? false,
      catalogueId: allergens?.itemId ?? "",
    },this.readImageProduct)

    const lang = localStorage.getItem("lang") || "en"; 
    await (i18n as any).changeLanguage(lang);
  }
  t(key:any, variables?: Record<string, any>) {
    return (i18n as any).t(key, { ns: "translation" , ...variables } )
  }
  // Customizable Area End
}
