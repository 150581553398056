import React from "react";

import // Customizable Area Start
{ Drawer,
Box,
Typography,
Grid,
InputAdornment,
FormControl,
MenuItem,
Select,
Button,
} from
// Customizable Area End
"@material-ui/core";

// Customizable Area Start
import LiveChat2Controller, {
  Props,
  configJSON,
} from "./LiveChat2Controller.web";
import { withStyles } from "@material-ui/core/styles";
import { liveChatCustomStyles } from "../../../components/src/LiveChatStyles.web";
import CustomProfileSidebar from "../../../components/src/CustomProfileSidebar";
import { backArrowIcon, menu } from "../../customisableuserprofiles/src/assets";
import withMediaQuery from "../../../components/src/withMediaQuery.web";
import CustomInputContent from "../../../components/src/CustomInput.web";
import {
  DoubleTick,
  defaultView,
  doubleTickGray,
  information,
  sortby,
} from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfiniteScroll from "react-infinite-scroll-component";
import { Chat, GroupedMessages } from "../../../components/src/ReusableEnums";
import NewChatModal from "../../../components/src/NewChatModal.web";
import ChatImagePreview from "../../../components/src/ChatImagePreview.web";
import WebLoader from "../../../components/src/WebLoader.web";
import {
  renderMedia,
  renderContent,
  formatChatDate,
  formatLiveChatTime,
} from "../../../components/src/ReusableFunctions";
// Customizable Area End

export class LiveChat2 extends LiveChat2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNoChatsView() {
    const { classes } = this.props;
    return (
      <Box className={classes.drawerContent}>
        <Box className={`${classes.profileBlockOuter} mvc-profile-outer`}>
          <Box className="profile-block-wrapper">
            <Box>
              <img
                className="default-image"
                src={defaultView}
                alt="defaultView"
              ></img>
            </Box>
            <Box className="container-2">
              <Typography className="heading">
                {this.translateChat(`${configJSON.defaultHeading}`)}
              </Typography>
              <Typography className="sub-heading">
                {this.translateChat(`${configJSON.subTxtDefault}`)}
              </Typography>
              <Button
                className={`primary-btn ${classes.primaryButton}`}
                onClick={this.openNewChat}
                data-test-id="openNewChat"
              >
                + {this.translateChat(`${configJSON.newChatBtn}`)}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  renderChatsList() {
    const { classes } = this.props;
    return (
      <Box className={classes.drawerContent}>
        <Box className={`${classes.profileBlockOuter}`}>
          <Grid container spacing={3}>
            <Grid item lg={6} className="chat-block-wrapper">
              {this.renderChatSearch()}
              {this.renderChatItems()}
              <Box className="new-chat-wrapper">
                <Button
                  className="btn-new-chat"
                  data-test-id="openNewChat"
                  onClick={this.openNewChat}
                >
                  + {this.translateChat(`${configJSON.newChatBtn}`)}
                </Button>
              </Box>
            </Grid>
            <Grid item lg={6} className="chat-container">
              {this.renderChatMessages()}
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }

  renderChatSearch() {
    const { classes } = this.props;
    return (
      <>
        <Typography className="conversations">
          {this.translateChat(`${configJSON.chatConversationsTxt}`)}
        </Typography>
        <Typography className="conversations-sub">
          {this.translateChat(`${configJSON.chatSubtext}`)}
        </Typography>
        <Box className="search-wrapper">
          <Box className="search-box">
            <CustomInputContent
              type="text"
              placeholder={this.translateChat(
                `${configJSON.searchPlaceholder}`
              )}
              value={this.state.chatRoomSearch}
              name="chatRoomSearch"
              onChange={this.handleAllInputChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "#225980" }} />
                </InputAdornment>
              }
              data-test-id="chatRoomSearch"
            />
            <Box className="sort-by" data-test-id="handleFiltersOPen">
              <img src={sortby} alt="bg-image" className="filter-image" />
            </Box>
          </Box>
        </Box>
        <Box className="sort-container">
          <Typography className="conversion-count-text">
            {this.state.chatRoomList?.length} &nbsp;
            {this.state.chatRoomList?.length>1 ? this.translateChat(`${configJSON.chatConversationsTxt}`) : this.translateChat(`${configJSON.chatConversationTxt}`)}
          </Typography>
          <FormControl className="select-control">
            <Select
              displayEmpty
              inputProps={{ IconComponent: () => null }}
              MenuProps={{
                anchorOrigin: { horizontal: "left", vertical: "bottom" },
                transformOrigin: { horizontal: "left", vertical: "top" },
                classes: { paper: classes.dropdownStyle },
                getContentAnchorEl: null,
              }}
              value={this.state.chatRoomSortBy}
              name="chatRoomSortBy"
              onChange={this.handleAllInputChange}
              defaultValue={this.translateChat(`${configJSON.sortByTxt}`)}
              renderValue={(value) => (
                <Box>
                  {value}
                  <ExpandMoreIcon />
                </Box>
              )}
              data-test-id="change-sort-chat"
            >
              <MenuItem value={this.translateChat(`${configJSON.recentText}`)}>
                {this.translateChat(`${configJSON.recentText}`)}
              </MenuItem>
              <MenuItem value={this.translateChat(`${configJSON.unreadText}`)}>
                {this.translateChat(`${configJSON.unreadText}`)}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </>
    );
  }
  renderChatItems() {
    return (
      <>
        {this.state.chatRoomList.length===0 ? 
        <Typography className="support-sub-txt">                 
          {this.translateChat(`${configJSON.noChatsFoundMsg}`)}
         </Typography> : 
         this.state.chatRoomList?.map((item: Chat) => {
          const nameParts = item.attributes.name.split(" ");
          const initials = nameParts
            .map((part: string) => part.charAt(0).toUpperCase())
            .join("");
          return (
            <Box
              className={
                this.state.messageId === item.id
                  ? "etoh-header-chat-active"
                  : "etoh-header-chat"
              }
              key={item.id}
              data-test-id={`getAllMessage-${item.id}`}
              onClick={() => this.getAllMessage(item, this.state.page)}
            >
              {renderContent(item.attributes.chat_type, initials)}
              <Box className="etoh-content">
                <Typography className="support-txt">
                  {item.attributes.name}
                </Typography>
                <Typography className="support-sub-txt">
                  {item.attributes.last_message.data &&
                    item.attributes.last_message.data.attributes.message}
                </Typography>
              </Box>
              <Box className="time-content">
                <Typography className="chat-time">
                  {item.attributes.last_message.data &&
                  formatChatDate( item.attributes.last_message.data.attributes.created_at )}
                </Typography>
                {item.attributes.unread_count === 0 ? (
                  <img
                    className="chat-double-tick"
                    src={DoubleTick}
                    alt="double tick"
                  />
                ) : (
                  <Box className="chat-count">
                  <Typography  className="chat-count-position">
                     {item.attributes.unread_count}
                   </Typography>
                   </Box>
                )}
              </Box>
            </Box>
          );
        })}
      </>
    );
  }

  renderChatMessages() {
    const { allSinglePersonChat, groupedMessages, itemData } = this.state;
    return (
      <>
        {allSinglePersonChat?.length > 0 ? (
          <Box className="etoh-header">
            {renderContent(
              itemData.attributes.chat_type,
              itemData.attributes.name.split(" ").map((part: string) => part.charAt(0).toUpperCase())
            )}
            <Box className="etoh-content">
              <Typography className="support-txt">
                {itemData.attributes.name}
              </Typography>
              <Typography className="support-sub-txt">
                {itemData.attributes?.receiver?.email}
                {itemData.attributes.chat_type === "etoh_shop_support" && this.translateChat(`${configJSON.ethoChatSubMsg}`)}
              </Typography>
            </Box>
            <Box className="info-content">
              <Typography className="info-content-img">
                <img src={information} alt="double-tick"></img>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className="default-chat-content">
            <Typography className="support-txt-default">
              {this.translateChat(`${configJSON.welcomeTxt}`)}
            </Typography>
          </Box>
        )}
        <Box
          id="parentChatID"
          style={{ maxHeight: 701, overflow: "auto", position: "absolute", bottom: 110, right: 0 }}
        >
          {allSinglePersonChat && allSinglePersonChat.length > 0 && (
            <InfiniteScroll
              data-test-id="InfiniteScroll"
              dataLength={allSinglePersonChat.length}
              next={this.fetchMoreData}
              hasMore={this.state.hasNextPage ? this.state.hasNextPage : false}
              loader={
                <Box className="loader" key="loader">
                  <Typography>
                    {this.translateChat(`${configJSON.loadingTxt}`)}
                  </Typography>
                </Box>
              }
              scrollableTarget="parentChatID"
            >
              {groupedMessages.map((group: GroupedMessages) => {
                return (
                  <Box key={group.date} className="scroll-content">
                    <Typography className="date-message">
                      {group.date}
                    </Typography>
                    {group.messages.map((item) => {
                      return (
                        <div key={item.id}>
                          <Box
                            className={
                              item.attributes.receiver_type === "AdminUser"
                                ? "chat-text-content-sender"
                                : "chat-text-content-reciever"
                            }
                          >
                            <Box className="right-content">
                              {item.attributes.message && (
                                <Typography className="help-message">                                
                                  {item.attributes.media_file &&
                                    renderMedia(
                                      item.attributes.media_file,
                                      item.attributes.media_type
                                    )}
                                  {item.attributes.message}
                                </Typography>
                              )}
                              
                                {item.attributes.media_file &&                            
                                  <Typography className="help-message">
                                    {renderMedia(
                                      item.attributes.media_file,
                                      item.attributes.media_type
                                    )}
                                  </Typography>
                                }                              
                              <Typography className="tick-time">
                                <img
                                  className="img"
                                  src={doubleTickGray}
                                  alt="doubleTickGray"
                                ></img>
                                {formatLiveChatTime(item.attributes.created_at)}                              
                              </Typography>
                            </Box>
                          </Box>
                        </div>
                      );
                    })}
                    <div ref={this.LiveChatEndRef} />
                  </Box>
                );
              })}
            </InfiniteScroll>
          )}
        </Box>
        <Box className="send-messge-area">
          <ChatImagePreview
            handleCloseAttachment={this.handleCloseAttachment}
            previews={this.state.previews}
            filesUploaded={this.state.filesUploaded}
            translateChat={this.translateChat}
            chatTxtMessage={this.state.chatTxtMessage}
            handleAllInputChange={this.handleAllInputChange}
            onBrowse={this.onBrowse}
            formRef={this.formRef}
            fileRef={this.fileRef}
            handleSubmitMessage={this.handleSubmitMessage}
            createExternalAndInternalChatMessgaeEvent={
              this.createExternalAndInternalChatMessgaeEvent
            }
            chatType={itemData.attributes.chat_type}
            handleOnDrop={this.handleOnDrop}
            messageId={this.state.messageId}
            data-test-id="ChatImagePreview"
          />
        </Box>
      </>
    );
  }
  renderHeader() {
    const { classes } = this.props;
    return (
      <Box className={classes.secondaryHeader}>
        <Box className="container">
          <Box
            onClick={this.handleBackBtn}
            data-test-id="handleBackBtn"
            className="image-wrapper"
          >
            <Box title="Back">
              <img
                className="back-img"
                alt="Arrow Back Icon"
                src={backArrowIcon}
              />
              {this.translateChat("Back")}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isMobile } = this.props;
    const { subscribedUserData } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.isLoading && <WebLoader />}
        <Box className={`wrapper ${classes.wrapper} ${classes.profileWrapper}`}>
          {this.renderHeader()}
          <Box className={`inner-wrapper ${classes.innerWrapper}`}>
            <Box className={classes.profileInnerWrapper}>
              <Box className="main-heading">
                <Box className="back-btn-wrap" onClick={this.handleBackBtn}>
                  <img alt="Arrow Back Icon" src={backArrowIcon} />
                </Box>
                <Typography className="heading" variant="h1">
                  {this.translateChat("Live Chat")}
                </Typography>
                <Box
                  className="profile-menu"
                  onClick={this.toggleProfileDrawer}
                  data-test-id="toggleProfileDrawer"
                >
                  <img src={menu} alt="menu" />
                </Box>
              </Box>
              <Box className={classes.drawerWrapper}>
                <Box className={classes.drawer}>
                  <Drawer
                    classes={{
                      paper: classes.drawerPaper,
                      root: classes.drawerRootWrapper,
                    }}
                    variant={isMobile ? "temporary" : "permanent"}
                    open={isMobile ? this.state.isDrawerOpened : true}
                  >
                    <CustomProfileSidebar
                      t={this.translateChat}
                      toggleMenu={this.closeDrawer}
                      title="Live Chat"
                      data-test-id="toggle-close"
                    />
                  </Drawer>
                </Box>
                {this.state.chatRoomAllList?.length === 0 
                  ? this.renderNoChatsView()
                  : this.renderChatsList()}
              </Box>
            </Box>
          </Box>
        </Box>
        {this.state.openNewChat && (
          <NewChatModal
            modalOpen={this.state.openNewChat}
            modalClose={this.closeNewChat}
            openExternalChat={this.state.openExternalChat}
            openExternalChatEvent={this.openExternalChatEvent}
            closeExternalChat={this.closeExternalChat}
            email={this.state.email}
            emailError={this.state.error.email}
            handleInviteMember={this.handleInviteMember}
            inviteMemberEvent={this.inviteMemberEvent}
            createExternalChatEvent={this.createExternalChatEvent}
            createExternalAndInternalChatEvent={
              this.createExternalAndInternalChatEvent
            }
            classes={classes}
            subscribedUserData={subscribedUserData}
            t={this.translateChat}
            handleAllInputChange={this.handleAllInputChange}
            userSearch={this.state.userSearch}
            userSortBy={this.state.userSortBy}
            closeEmailVarification={this.closeEmailVarification}
            openEmailvarification={this.state.openEmailvarification}
            createEtohSupportChatEvent={this.createEtohSupportChat}
            data-test-id="NewChatModal"
          />
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(liveChatCustomStyles, { withTheme: true })(
  withMediaQuery("(max-width:991px)")(LiveChat2)
);
// Customizable Area End
