import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  MenuItem,
  Checkbox,
  InputAdornment,
  Select,
  FormControl,
  List,
  ListItem,
  ListSubheader,
  Popover,
  Chip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CloseIcon from "@material-ui/icons/Close";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import WebLoader from "../../../components/src/WebLoader.web";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {
  filterSmIcon,
  wineImage,
  plusIcon,
  minusIcon,
  editIcon,
} from "./assets";
import CustomInputWeb from "../../../components/src/CustomInput.web";
import { customStyles } from "../../../components/src/Inventory/SendToContactStyles.web";
import SendToContactController, { Props } from "./SendToContactController.web";
import { extractItemDetails, getImageForSubCategory, showLettersFromName } from "../../../components/src/ReusableFunctions";
import CreateContactWeb from "../../ContactsList/src/CreateContact.web";

export const configJSON = require("./config");
// Customizable Area End

export class SendToContact extends SendToContactController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  getListItems = () => {
    const { classes } = this.props;
    const sendToContactDataString = localStorage.getItem("sendToContcatData");
    let getSendToContactData = sendToContactDataString ? JSON.parse(sendToContactDataString) : null;
    
    return (
      <>
        <Box className="send-contact-right">
          <Box className="send-right-inner">
            <Box className="heading-block">
              <Box className="title">{this.translateText(`${configJSON.adjustQuantityTxt}`)}</Box>
              <Link to="#" className="heading-link">
                <img className="show-sm" src={editIcon} alt="product-img" />
                <Box component="span" className="hide-sm">
                  {this.translateText(`${configJSON.editProductText}`)}
                </Box>
              </Link>
            </Box>
            <Typography className="inventory-subtitle-txt">
              {this.translateText(`${configJSON.adjustDataText}`)}
            </Typography>
            <Box className="content-details">
              <Box className="product-list-block">
                {getSendToContactData?.length >0 &&  getSendToContactData?.map((product:any) => {
                  console.log("test data",product)
                  const {
                    category,
                    subCategory,
                    targetName,
                    brandName,
                    materialName,
                    colorName,
                    style,
                    appellation,
                    grapeVariety,
                    vintage,
                    volume,
                    area,
                    unitOfMeasurement,
                    stock_qty,
                    itemName,
                  } = extractItemDetails(product);
                  console.log("test data",itemName,unitOfMeasurement)
                    const price = product.attributes.prices?.[0]?.amount ?? "0";
                    const quantity = product.attributes.stock_qty ?? "0"
                    return (
                     <Box key={`prod-${product}`} className="product-col">
                     <Box className="product-inner">
                       <Box className="product-img">
                         {getImageForSubCategory(subCategory, product.attributes, true)}
                       </Box>
                       <Box className="product-details">
                         <Box className="details-left">
                           <Box className="product-name">{itemName}</Box>
                           <Box className="product-desc">
                            {brandName}
                            {style}
                            {targetName !== "" && brandName !== ""
                              ? `, ${targetName}`
                              : targetName}
                            {materialName !== "" && brandName !== ""
                              ? `, ${materialName}`
                              : materialName}
                            {colorName !== "" && (brandName !== "" || style !== "")
                              ? `, ${colorName}`
                              : colorName}
                            {appellation}
                            {grapeVariety !== "" && appellation !== ""
                              ? `, ${grapeVariety}`
                              : grapeVariety}, &nbsp;
                              {vintage || 0} ml
                           </Box>
                           <Box className="qty-input">
                             <Button className="qty-count qty-count-minus">
                               <img src={minusIcon} alt="minus-icon" />
                             </Button>
                             <Box className="product-qty">{quantity}/50</Box>
                             <Button className="qty-count qty-count-plus">
                               <img src={plusIcon} alt="plus-icon" />
                             </Button>
                           </Box>
                         </Box>
                         <Box className="details-right">
                           <Box className="price-btn">
                             <FormControl
                               className={classes.selectFormControl}
                             >
                               <Select
                                 MenuProps={{
                                   anchorOrigin: {
                                     horizontal: "left",
                                     vertical: "bottom",
                                   },
                                   transformOrigin: {
                                     horizontal: "left",
                                     vertical: "top",
                                   },
                                   classes: {
                                     paper: classes.dropdownStyle,
                                   },
                                   getContentAnchorEl: null,
                                 }}
                                 IconComponent={(props) => (
                                   <KeyboardArrowDown {...props} />
                                 )}
                                 disableUnderline
                                 displayEmpty
                                 data-test-id="sort-by-input-2"
                                 defaultValue="Retail Price"
                               >
                                 <MenuItem value="Retail Price">
                                   {this.translateText(`${configJSON.ShowAsOption1}`)}
                                 </MenuItem>
                                 <MenuItem value="Wholesale Price">
                                 {this.translateText(`${configJSON.ShowAsOption2}`)}
                                 </MenuItem>
                                 <MenuItem
                                   value="Custom Price"
                                   onClick={(e) => this.handlePopoverOpen(e)}
                                   data-testId={`customPrice-${product}`}
                                 >
                                   {this.translateText(`${configJSON.ShowAsOption3}`)}
                                 </MenuItem>
                               </Select>
                             </FormControl>
                           </Box>
                           <Typography className="price-txt">
                             ${price}
                           </Typography>
                         </Box>
                       </Box>
                     </Box>
                   </Box>
                    );

                  })}
              </Box>
              <Box className="sub-total">
                Subtotal ({getSendToContactData?.length} items): <Box className="price">
                ${getSendToContactData?.reduce((acc:any, product:any) => {
      const price = product.attributes.prices?.[0]?.amount ?? 0;
      return acc + price;
    }, 0).toFixed(2)}
      </Box>
              </Box>
              
              {this.state.nextStepContent ? (
                <Box className="button-wrapper full-btn">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    onClick={this.sendEmailtoContact}
                    data-test-id="send-email-contact"
                  >
                    {configJSON.sendButton}
                  </Button>
                </Box>
              ) : (
                <Box className="button-wrapper full-btn">
                  <Button
                    className={`primary-btn ${classes.primaryButton}`}
                    onClick={this.nextStepClick}
                    data-testId="move-step-2"
                  >
                    Next Step
                  </Button>
                </Box>
              )}

              <Popover
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ top: "0" }}
                classes={{ paper: `${classes.customPricePopover}` }}
              >
                <Box className={classes.customPriceBody}>
                  <Box className="price-wrapper">
                    <CustomInputWeb
                      value=""
                      label=""
                      placeholder="Start typing custom price"
                    />
                  </Box>
                  <Box className="button-wrapper half-btn">
                    <Button
                      className={`secondary-btn ${classes.secondaryButton}`}
                      title="Cancel"
                      onClick={this.handlePopoverClose}
                    > 
                      {this.translateText(`${configJSON.cancelButton}`)}
                    </Button>
                    <Button
                      className={`primary-btn ${classes.primaryButton}`}
                      title="Update"
                    >
                      {this.translateText(`${configJSON.updateButton}`)}
                    </Button>
                  </Box>
                </Box>
              </Popover>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  showCreateContactForm = () => {
    const {classes} = this.props;
    if(this.state.showCreateContact){
      return (
      <CreateContactWeb 
      handleCloseDialog={this.handleCloseCreateContact}
      handleMinimizeCreateContact={this.openFloatCreateContact}
      id="send-to-contact"
      navigation="/contacts"
      classes={classes}
      data-test-id="create-contact-form"
      contactList={this.getContactLists}
      />
      )
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { nextStepContent } = this.state;
    const filteredContacts = this.getFilteredAndSortedContacts();
    let constactListData;

    const {isSearching, allContactLists } = this.state;
    if (isSearching) {
      constactListData = filteredContacts;
    } else {
      constactListData = allContactLists;
    }
    return (
      <>
        {this.state.isLoading && <WebLoader />}
        <Box>
          <Box className={`${classes.headerSection} `}>
            <Typography
              className="backStyle"
              onClick={this.navigateBacktoInventory}
              data-test-id="back-btn-inventory"
            >
              <KeyboardBackspaceIcon className="backIcon" />
              {this.translateText(`${configJSON.backButton}`)}
            </Typography>
          </Box>
          <Box className={classes.sendContactOuter}>
            {!nextStepContent && (
              <Box className="main-heading">
                <Box className="header-section">
                  <Box className="heading-left">
                    <Box className="heading-box">
                      <Typography className="heading">
                        {this.translateText(`${configJSON.chooseContacts}`)}
                      </Typography>
                    </Box>
                    <Typography className="inventory-subtitle-txt">
                      {this.translateText(`${configJSON.subText}`)}
                    </Typography>
                  </Box>
                  <Box className="inventory-buttons">
                    <Button className="export-btn" data-test-id="contact-modal-choose" onClick={this.createContact}>{this.translateText(`${configJSON.createContactTxt}`)}</Button>
                  </Box>
                </Box>
              </Box>
            )}
            {nextStepContent && (
              <Box className="main-heading">
                <Box className="header-section">
                  <Box className="heading-left">
                    <Box className="heading-box">
                      <Typography className="heading">{this.translateText(`${configJSON.writeMessage}`)}</Typography>
                    </Box>
                    <Typography className="inventory-subtitle-txt">
                      {this.translateText(`${configJSON.craftMsg}`)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Box className="send-contact-main">
              {!nextStepContent && (
                <>
                  <Box className="send-contact-left">
                    <List
                      className={`${classes.assigneeMultiSelectList} assignee-multiSelect-list`}
                    >
                      <ListSubheader className="search-filter">
                        <CustomInputWeb
                          placeholder={this.translateText(`${configJSON.filterSearchPlaceholder}`)}
                          autoFocus={true}
                          value={this.state.searchValue}
                          onChange={(event:any)=>this.handleSearch(event)}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          data-test-id="search-value"
                        />
                        <Box
                          className="filter-group"
                          data-testid="filterTeamHandler"
                        >
                          <img src={filterSmIcon} alt="filter-group" />
                        </Box>
                      </ListSubheader>
                      <ListSubheader className="multiselect-membres">
                        <Box className="total-member">
                          {constactListData.length}  {constactListData.length >1 ?  this.translateText(`${configJSON.contactsText}`): this.translateText(`${configJSON.contactText}`) } 
                        </Box>
                        <FormControl className="select-control sortby-control">
                          <Select
                            IconComponent={ExpandMoreIcon}
                            MenuProps={{
                              anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom",
                              },
                              transformOrigin: {
                                horizontal: "left",
                                vertical: "top",
                              },
                              classes: { paper: classes.dropdownStyle },
                              getContentAnchorEl: null,
                            }}
                            displayEmpty
                            defaultValue="Sort by"
                            onChange={(event:any) => this.handleSortBy(event)}
                            data-test-id="sort-value"
                            renderValue={(value: any) => {
                              return <Box>{value}</Box>;
                            }}
                          >
                            <MenuItem value="A-Z">A-Z</MenuItem>
                            <MenuItem value="Z-A">Z-A</MenuItem>
                          </Select>
                        </FormControl>
                      </ListSubheader>
                      {constactListData.map((contact: any) => {
                        const fullName =
                          contact.attributes.first_name +
                          " " +
                          contact.attributes.last_name;
                        return (
                          <ListItem key={contact.id}>
                            <Box className="assignee-detail">
                              <Box className="assignee-left">
                                <Box className="assignee-profile">
                                  {showLettersFromName(fullName)}
                                </Box>
                                <Box className="assignee-info">
                                  <Box className="assignee-name">
                                    {fullName}
                                  </Box>
                                  <Box className="assignee-email">
                                    {contact.attributes.email}
                                  </Box>
                                </Box>
                              </Box>
                              <Box className="assignee-right">
                                <Checkbox
                                  tabIndex={-1}
                                  className="checklist-check"
                                  disableRipple
                                  onClick={() =>
                                    this.handleContactsSelect(
                                      contact.id,
                                      fullName,
                                      contact.attributes.email
                                    )
                                  }
                                  checked={this.state.emailSelectedContacts.some(
                                    (selectedContact) => selectedContact.contactId === contact.id
                                  )}
                                  data-test-id={`select-contact-${contact.id}`}
                                />
                              </Box>
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                  {this.getListItems()}
                </>
              )}

              {nextStepContent && (
                <>
                  <Box className="send-contact-left">
                    <Box className="task-form" component="form">
                      <Box className="form-info-wrapper">
                        <Box className="form-row">
                          <Box className="form-col">
                            <Box className="chip-block-wrapper">
                              <Box className="chip-wrapper">
                                {this.state.emailSelectedContacts.slice(0, 3).map(
                                  (selectedContact) => {
                                    return (
                                      <Chip
                                        className={classes.chipButton}
                                        deleteIcon={<CloseIcon />}
                                        key={selectedContact.contactId}
                                        label={
                                          <>
                                            <Box className="name">
                                              {selectedContact.fullName}
                                            </Box>
                                            <Box className="email">
                                              {selectedContact.email}
                                            </Box>
                                          </>
                                        }
                                      />
                                    );
                                  }
                                )}
                               {this.state.emailSelectedContacts.length > 3 && (
                                <Chip
                                  className={`more-btn ${classes.chipButton}`}
                                  label={`${this.state.emailSelectedContacts.length - 3} More`}
                                />
                               )}
                              </Box>
                              <Link to="#"  onClick={this.nextStepClick} className="heading-link">
                                {this.translateText(`${configJSON.modifyContactTxt}`)}
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="form-row">
                          <Box className="form-col">
                            <CustomInputWeb
                              type="text"
                              label={this.translateText(`${configJSON.subjectTxt}`)}
                              value={this.state.emailSubject}
                              isRequired={true}
                              name="emailSubject"
                              errors={this.state.emailSubjectError}
                              onChange={(event: any) =>
                                this.handleEmailsInput(
                                  "emailSubject",
                                  event.target.value
                                )
                              }
                              data-test-id="emailSubject"
                            />
                          </Box>
                        </Box>
                        <Box className="form-row">
                          <Box className="form-col">
                            <CustomInputWeb
                              type="text"
                              //isRequired={true}
                              label={this.translateText(`${configJSON.messageTxt}`)}
                              isMultiline
                              rows={6}
                              value={this.state.emailMessage}
                              name="emailMessage"
                              onChange={(event: any) =>
                                this.handleEmailsInput(
                                  "emailMessage",
                                  event.target.value
                                )
                              }
                              data-test-id="emailMessage"
                            />
                          </Box>
                        </Box>
                        <Box className="form-row">
                          <Box className="form-col">
                            <Box className="items-list-wrapper">
                              <Box className="items-list">
                                <Box className="items-title">
                                  <Box className="primary-txt">{this.translateText(`${configJSON.attachmentText}`)}</Box>
                                  <Link to="#" className="title-icon">
                                    <AttachFileIcon className="close-icon" />
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="form-row">
                          <Box className="form-col">
                            <Box className="items-list-wrapper">
                              <Box className="items-list">
                                <Box className="items-title">
                                  <Box className="primary-txt">
                                    Product_catalogue.pdf
                                  </Box>
                                  <Link to="#" className="title-icon">
                                    <CloseIcon className="close-icon" />
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  {this.getListItems()}
                </>
              )}
            </Box>
          </Box>
        </Box>
        {this.showCreateContactForm()}
      </>
    );
  }

  // Customizable Area End
}

// Customizable Area Start

export default withStyles(customStyles, { withTheme: true })(SendToContact);
// Customizable Area End
