// Customizable Area Start
import React from "react";

import { CardMedia } from "@material-ui/core";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core/styles";
import { customStyles } from "./ContactsListStyles.web";
import { toast } from "react-toastify";
import { callMergeUndo, multipleAccount, warningIcon , bottle} from "./assets";
import i18n from "../../../web/src/utilities/i18n";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof customStyles> {
  navigation: any;
  id: string;
  classes: any;
  isMobile?: boolean;
}

interface S {
  pageLink: string;
  contacts: any;
  anchorEl: any;
  contactDetails: any;

  showContactDetailModal: boolean;
  selectedContactId: string;
  openedPopoverId: any;

  addGroupToContact: boolean;
  floatCreateContact: boolean;
  createContactPopoverShown: boolean;
  searchKeyword: string;

  filterGroups: any;
  filterCompany: any;
  filterBusinessCategories: any;
  filterShippingCountry: any;

  anchorElMore: any;
  hasAnOpenDeal: boolean;
  hasInteractions: boolean;
  hasFavoritesProduct: boolean;
  hasNotes: boolean;

  searchGroup: string;
  groups:any;
  sortValue:string;
  sortedAndFilteredData:any;
  isSortAndFiltering:boolean;
  isLoading:boolean;
  searchedContacts: any;
  isSearching:boolean;
  isFiltering:boolean;
  filterddContacts:any;
  name:boolean;
  email:boolean;
  group:boolean;
  favProduct:boolean;
  interaction:boolean;
  openDeal:boolean;
  anchorElColmun:any;
  openContactMenu:any;
  businessCategoryList:any;
  isMergeContact:boolean;
  isSelectMergeInfo:boolean;
  contactMergeDetails:any;
  viewContactTabsValue: number;
  checked: number;
  newStatus: any;
  chooseGroupChecked: any[];
  showMoreGroups: boolean;

  noteMenuAnchor: any;
  noteUpdateId: string;
  isShowEditContact: boolean;
  isShowAddNote: boolean;
  isShowEditNote: boolean;
  selectedNote: string;
  isShowDeleteNoteConfirmation: boolean;
  isDeleteContact: boolean;
  delModalOpen: boolean;
  delContactDetails: {
    firstName: string,
    lastName: string,
    id: string,
  };
  viewSingleDetail:any,
  isAddContactGroup: boolean;
  notes:string;
  notesData:any;
  noteViewId:string;
  createDealPopoverShown:boolean;
  anchorElDeal: any;
  viewContactId:string;
  selectContacts:boolean;
  isActiveChooseStage:boolean;
  currentDealStage: {
    currentdealId: string;
    currentdealStage: number | undefined;
  };
  addDealNote: any;
  countryDataList:any;
  openGroup:boolean;
  isMenuDrawerOpened: boolean;
  undoFavProductId:any;
  sortValueFavPRoduct:string;
  sortValueNotes:string;
  sortValueDeals:string;
}

interface SS {
  id: any;
}

export default class ContactsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  getContactListRequestId: string = "";
  getContactRequestId: string = "";
  userSessionData: any;
  userToken: any;
  getAddToGroupApiId:string = "";
  addtogroupApiId:string = "";
  getSortAndFilterRequestId:string="";
  getSearchListRequestId:string="";
  getBusinessCategoryListApiID:string="";
  mergeContactApiId:string="";
  mergeContactUndoApiId:string="";
  fileRef: React.RefObject<HTMLInputElement>;
  formRef: React.RefObject<HTMLFormElement>;
  deleteContactAPIID: string = "";
  getContactlistDataApiID:string="";
  getAllNotesApiID:string="";
  createNotesApiID:string="";
  deleteNoteAPI:string="";
  updateNoteAPI:string="";
  updateDealStatusRequestId: string = "";
  countryListDataApiCallID:string="";
  deleteContactFavProductAPIID:string="";
  undoFavProductApiRequestId:string="";
  addAsFavProductApiRequestId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.fileRef = React.createRef();
    this.formRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    this.state = {
      pageLink: "",
      contacts: [],
      contactDetails: [],
      showContactDetailModal: false,
      selectedContactId: "",

      openedPopoverId: null,
      anchorEl: null,
      addGroupToContact: false,
      floatCreateContact: false,
      createContactPopoverShown: false,
      searchKeyword: "",

      filterGroups: [],
      filterCompany: [],
      filterBusinessCategories:[],
      filterShippingCountry: [],
      anchorElMore: null,
      hasAnOpenDeal: false,
      hasInteractions: false,
      hasFavoritesProduct: false,
      hasNotes: false,

      searchGroup:"",
      groups:[],
      sortValue:"asc",
      sortedAndFilteredData:[],
      isSortAndFiltering:false,
      isLoading:false,
      searchedContacts: [],
      isSearching:false,
      isFiltering:false,
      filterddContacts:[],
      name:false,
      email:false,
      group:false,
      favProduct:false,
      interaction:false,
      openDeal:false,
      anchorElColmun:null,
      openContactMenu:null,
      businessCategoryList:[],
      isMergeContact:false,
      isSelectMergeInfo:false,
      contactMergeDetails:[],
      viewContactTabsValue: 0,
      checked: 1,
      newStatus: null,
      chooseGroupChecked: [0],
      showMoreGroups: false,
      noteMenuAnchor: null,
      noteUpdateId: "",
      isShowEditContact: false,
      isShowAddNote: false,
      isShowEditNote: false,
      selectedNote: "",
      isShowDeleteNoteConfirmation: false,
      isDeleteContact: false,
      delModalOpen: false,
      delContactDetails: {
        firstName: "",
        lastName: "",
        id: "",
      },
      isAddContactGroup: false,
      viewSingleDetail:{},
      notes:"",
      notesData: [],
      noteViewId:"",
      createDealPopoverShown:false,
      anchorElDeal: null,
      viewContactId:"",
      selectContacts:false,
      isActiveChooseStage:false,
      currentDealStage: {
        currentdealId: "",
        currentdealStage: 0,
      },
      addDealNote: "",
      countryDataList:[],
      openGroup:false,
      isMenuDrawerOpened: false,
      undoFavProductId:"",
      sortValueFavPRoduct:"",
      sortValueNotes:"",
      sortValueDeals:"",
    };
   this.userSessionData = sessionStorage.getItem("userData")  || localStorage.getItem("userData");   
    this.userToken = JSON.parse(this.userSessionData);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.setState({ pageLink: window.location.pathname });
    this.getContactsList();
    this.getGroupsList();
    this.getBusinessCategoryList(); 
    this.countryListDataAPICallEvent();
    const lang = localStorage.getItem("lang") ?? "en"; 
    await i18n.changeLanguage(lang); 
  }
  t(key:any) {
    return i18n.t(key, { ns: "translation" } )
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.error) {
        this.createToastNotification(this.t(responseJson.error));
      } else {
        switch (apiRequestCallId) {
          case this.getContactListRequestId:
            this.getContactListResponse(responseJson);
            break;
          case this.getContactRequestId:
            this.getContactDetailsResponse(responseJson.data);
            this.setState({viewContactId:responseJson.data.id}) 
            break;
          case this.getAddToGroupApiId:
            this.setGroupListforGroup(responseJson.data);
            break;
          case this.addtogroupApiId:           
            this.setAddGroupResponse(responseJson);
            break;
          case this.getSortAndFilterRequestId:
            this.setState({isLoading: false})
            this.setState({sortedAndFilteredData:responseJson.data})
            break;
          case this.getSearchListRequestId:
            this.setState({isLoading: false})
            this.setState({searchedContacts:responseJson.data})
            break;
          case this.getBusinessCategoryListApiID:
            this.setBusinessCategoryListforGroup(responseJson);
            break;
          case this.countryListDataApiCallID:
            this.setState({countryDataList: responseJson.data});   
            break;
          case this.mergeContactApiId:
            this.setMergeContactData(responseJson);
            break;
          case this.mergeContactUndoApiId:
            this.getContactsList();
            break;
            case this.deleteContactAPIID:
              this.deleteContactResponse(responseJson);
              break;
        
            case this.getContactlistDataApiID:
              this.viewContactResponse(responseJson);
              break;
        
            case this.getAllNotesApiID:
              this.setNotesList(responseJson.data);
              break;
        
            case this.createNotesApiID:
              this.handleHideAddNote();
              this.setState({ notes: "" });
              this.getAllNotes();
              break;
        
            case this.deleteNoteAPI:
              this.setState({ isLoading: false });
              this.onHideDeleteNoteConfirmation();
              this.getAllNotes();
              break;
        
            case this.updateNoteAPI:
              this.onHideEditNoteClose();
              this.getAllNotes();
              break;
            case this.updateDealStatusRequestId:
              this.setState({ isActiveChooseStage: false });
              this.setState({ isLoading: false });
              this.createToastNotificationSuccessMsg(responseJson.message);
              this.getContactDetails(this.state.selectedContactId)
              break;
            case this.deleteContactFavProductAPIID:
              this.getContactDetails(this.state.contactDetails.id)
              this.createToastNotificationSucces("Favorite Product Removed",bottle)
              this.setState({ isLoading: false });
              break;
            case this.undoFavProductApiRequestId:
              this.getContactDetails(this.state.contactDetails.id)             
              this.setState({ isLoading: false });
              break;   
            case this.addAsFavProductApiRequestId:
              this.createToastNotificationSuccessMsg(responseJson.message);           
              this.getContactDetails(this.state.contactDetails.id)  
              this.setState({ isLoading: false });
              break;
          default:
            this.parseApiCatchErrorResponse(errorReponse);
            break;
        }
      }
    }
  }

  getContactListResponse = (response: any) => {
    this.setState({ contacts: response.data });
    localStorage.setItem("contactCount",this.state.contacts.length)
    localStorage.removeItem("draftContact")
  };
  setMergeContactData =(response:any)=>{
    this.createToastNotificationSuccess(this.t("Contact Merged"),callMergeUndo)
    this.setState({isSelectMergeInfo:false})
    this.setState({
      showContactDetailModal:true,
    });
    this.handleMergeContactClose()
    this.handleShowContactDetail(response.data.id)
    this.getContactsList()    
  }
  handleShowContactDetail = (id: string) => {
    if (id !== "" && parseInt(id) > 0) {
      this.getContactDetails(id);
    }
  };

  handleCloseContactDetail = () => {
    this.setState({ showContactDetailModal: false, contactDetails: "" });
    this.getContactsList();
  };

  handleOpenAddGroupToContact = () => {
    this.setState({ addGroupToContact: true });
  };
  
  handleOpenMenu = (event : any)=>{
    if (event) {
      this.setState({ openContactMenu: event.currentTarget });
    }
  }
  handleCloseMenu = ()=>{
    this.setState({openContactMenu:null})
  }
  handleBulkUploadNavigation=()=>{
    window.location.href="/contacts-import"
  }
  handleCloseAddGroupToContact = () => {
    this.setState({ addGroupToContact: false });
  };
  
  createToastNotificationSuccess = (toastMesssage: string, toastIcon: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssage}`)}</div>{" "}
        <a href="#" data-test-id="undoApiAction" className="toast-link" onClick={this.undoMergeContact}>
          {this.t("undo")}
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata"/>,
      }
    );
  };
  createToastNotificationSucces = (toastMesssage: string, toastIcon: string , action?:any) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssage}`)}</div>
        <a href="#" data-test-id="undoApiAction-2" className="toast-link">
          {this.t("undo")}
        </a>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        icon: () => <img src={toastIcon} alt="emptydata"/>,
      }
    );
  };
  createToastNotificationSuccessMsg = (toastMessage: string) => {
    toast.success(this.t(`${toastMessage}`), {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  setAddGroupResponse =(response:any)=>{
    if(response.message) {
      this.createToastNotificationSucces(this.t("Added to Group"),multipleAccount)
      this.getContactDetails(this.state.contactDetails.id);
      this.handleCloseAddGroupToContact();
    }
  }

  handleOpenCreateContact = (
    event: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    if (event) {
      this.setState({
        anchorEl: event.currentTarget,
        createContactPopoverShown: true,
      });
    }
  };

  handleCloseCreateContact = () => {
    this.setState({ anchorEl: null, floatCreateContact: false}, () => this.getBusinessCategoryList());
  };

  handleOpenMoreMenu = (event: any) => {
    if (event) {
      this.setState({ anchorElMore: event.target });
    }
  };
  handleColumnOpenMenu = (event: any) => {
    if (event) {
      this.setState({ anchorElColmun: event.target });
    }
  };
  handleColumnCloseMenu = () => {
    this.setState({ anchorElColmun: null });
  };

  handleCloseMoreMenu = () => {
    this.setState({ anchorElMore: null });
  };

  openFloatCreateContact = () => {
    this.setState({
      floatCreateContact: true,
      createContactPopoverShown: false,
      anchorEl: null,
    }, () => this.getBusinessCategoryList());
  };
  handleEscCreateContact = (event:any) => {
    event.stopPropagation();
    if (event.key === "Escape") {
      this.openFloatCreateContact();
    }
    
  }

  closeFloatCreateContact = () => {
    this.setState({ floatCreateContact: false });
    localStorage.removeItem("draftContact")
  };

  openCreateContactPopoverShown = () => {
    this.setState({ createContactPopoverShown: true });
  };

  closeCreateContactPopoverShown = () => {
    this.setState({ createContactPopoverShown: false });
  };

  handleSearch = (text: string) => {
    this.setState({ searchKeyword: text , isSearching:true }, () => {
      this.getSearchtList(); 
    });
  };

  handleSearchGroup = (text: string) => {
    this.setState({ searchGroup: text });
  };

   handleInputChange = (event: any) => {
    const { name, value } = event.target;  
    this.setState((prevState:any) => {
      const allowedNames = ['filterBusinessCategories', 'filterGroups'];

      let updatedValue;
      
      if (allowedNames.includes(name)) {
        const selectedValue = parseInt(value, 10);
        const isSelected = prevState[name].includes(selectedValue);
        if (isSelected) {
          updatedValue = prevState[name].filter((item: any) => item !== selectedValue);
        } else {
          updatedValue = [selectedValue];
        }
      } else {
        updatedValue = value;
      }
      return {
        ...prevState,
        [name]: updatedValue,
        isSortAndFiltering: true,
    }}, () => {
      this.getSortAndFilter();
    });
  };

   handleChecked = (event:any) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: checked,
      isSortAndFiltering: true,
    }), () => {
      this.getSortAndFilter();
    });
  };

  createToastNotification = (toastMesssage: string) => {
    toast.error(this.t(`${toastMesssage}`), {
      position: toast.POSITION.TOP_CENTER,
      icon: () => (
        <CardMedia component="img" src={warningIcon} alt="emptydata" />
      ),
    });
  };

  getContactDetailsResponse = (data: any) => {
    if(!this.state.isSelectMergeInfo){
      localStorage.setItem('isMergeContcatId', data.id);
      const contactDetailsString = JSON.stringify(data.attributes);
      localStorage.setItem("contactDefaultDetails", contactDetailsString);
    }
    this.setState({
      contactDetails: data.attributes,
      showContactDetailModal: true,
      selectedContactId: data.id,
    });
    if(this.state.isSelectMergeInfo){
      this.setState({
        contactMergeDetails: data.attributes,
      });
    }
    this.getAllNotes();
  };

  getContactsList = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.viewContactsPath
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContactDetails = (id: string) => {
    if (id !== "" && parseInt(id) > 0) {
      const header = {
        token: this.userToken.meta.token,
      };

      let endPoint = `${configJSON.viewContactsPath}/${id}/?token=${this.userToken.meta.token}`;

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getContactRequestId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.methodGET
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  
  getGroupsList = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAddToGroupApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groups
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getBusinessCategoryList = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessCategoryListApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.businessCategory
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setGroupListforGroup = (data: any) => {
    this.setState({groups: data})
  }
  setBusinessCategoryListforGroup = (data: any) => {
    this.setState({businessCategoryList: data})
  }

  addToGroup = () => {  
    let group_id = localStorage.getItem("addGroupId"); 
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    let endPoint = `${configJSON.addToGroup}?contact_id=${this.state.contactDetails.id}&group_id=${group_id}`;
    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addtogroupApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

   onChangeSort = (text:string) => {
    this.setState({ sortValue: text , isSortAndFiltering: true }, () => {
      this.getSortAndFilter(); 
    });
   };

  getSortAndFilter = () => {
    const newValue = this.state.sortValue;

    let processedValue = '';

    switch (newValue) {
      case 'A-Z':
        processedValue = 'asc';
        break;
      case 'Z-A':
        processedValue = 'desc';
        break;   
    }
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSortAndFilterRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewContactsPath}?first_name=${processedValue}&shipping_country=${this.state.filterShippingCountry}&company=${this.state.filterCompany}&business_category=${this.state.filterBusinessCategories}&have_notes=${this.state.hasNotes}&group=${this.state.filterGroups}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchtList = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchListRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchApiPath}?search_term=${this.state.searchKeyword}`,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    this.setState({isLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMergeContactClose=()=>{
     this.setState({isMergeContact:false})
     window.history.back();
     this.setState({
      isSearching:false,
      isSortAndFiltering:false,
      sortValue:"",
      searchKeyword:"" ,
      filterShippingCountry:"",
      filterCompany:"",
      filterBusinessCategories:"",
      filterGroups:"",
      hasAnOpenDeal: false,
      hasInteractions: false,
      hasFavoritesProduct: false,
      hasNotes: false,
     })
     this.getContactsList()
  }

  handleMergeInfoSelectionOpen=(id:string)=>{
    this.getContactDetails(id)
    this.setState({isSelectMergeInfo:true})
  }
  handleMergeInfoSelectionClose=()=>{
    this.setState({isSelectMergeInfo:false})
    }

  mergeContact = (data:any,id:any) => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.mergeContactApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mergeContcatApiPath}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  undoMergeContact = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.mergeContactUndoApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.mergeContactUndo}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  viewContactResponse = (response: any) => {
    this.setState({viewSingleDetail:response.data})
    this.setState({ isShowEditContact: true })
  }

  setNotesList = (response:any)=>{
    this.setState({notesData: response})
  }

  createToastNotificationSuccesss = (toastMesssage: string) => {
    toast.success(
      <div className="toast-notification">
        <div className="notification-txt">{this.t(`${toastMesssage}`)}</div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        // icon: () => <img src={checkedIcon} alt="emptydata" />,
      }
    );
  };

  deleteContactResponse = (response: any) => {
    this.createToastNotificationSuccesss(this.t(response.message));
    this.closeDeleteModal();
    this.getContactsList()
    this.handleCloseContactDetail();
  }

  onEditContactOpen = () => {
    this.viewEditDataAction()
  };

  onEditContactClose = () => {
    this.setState({ isShowEditContact: false });
    const isEdit= localStorage.getItem("isEdit");
    if(isEdit==="true"){
      this.getContactDetails(this.state.selectedContactId)
    }
  };

  handleOpenNoteMenu = (event: React.MouseEvent<HTMLButtonElement>,id:string,content:string) => {
    this.setState({noteViewId:id})
    this.setState({noteUpdateId:id})
    this.setState({selectedNote:content})
    this.setState({ noteMenuAnchor: event.currentTarget });
  };

  handleCloseNoteMenu = () => {
    this.setState({ noteMenuAnchor: null, noteUpdateId: "" });
  };

  handleShowAddNote = () => {
    this.setState({ isShowAddNote: true });
  };

  handleHideAddNote = () => {
    this.setState({ isShowAddNote: false });
    this.setState({notes:""})
  };

  onShowEditNoteOpen = (noteId: string, content:string) => {
    this.setState({ noteMenuAnchor: null,selectedNote: content, isShowEditNote: true, noteUpdateId: noteId });
  };

  onHideEditNoteClose = () => {
    this.setState({ isShowEditNote: false, noteMenuAnchor: null, noteUpdateId: "" });
  };

  handleCloseMenuAnchor = () => {
    this.setState({ noteMenuAnchor: null, noteUpdateId: "" });
  }

  onUpdateNoteAction = (note: string) => {
    this.setState({ selectedNote: note});
  };

  onShowDeleteNoteConfirmation = () => {
    this.setState({ isShowDeleteNoteConfirmation: true, noteMenuAnchor: null });
  };

  onHideDeleteNoteConfirmation = () => {
    this.setState({ isShowDeleteNoteConfirmation: false });
  };

  setViewContactTabsValue = (obj: any, val: any) => {
    this.setState({
      viewContactTabsValue: val,
    });
  };

  handleInputChangeAction = (event:any) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  toggleShowGroups = () => {
    this.setState({ showMoreGroups: !this.state.showMoreGroups });
  };

	formatCreatedDate = (inputDate: any): React.ReactNode => {
		const date = new Date(inputDate);
		
		// Define month names
		const months = [
			"Jan", "Feb", "Mar", "Apr",
			"May", "Jun", "Jul", "Aug",
			"Sep", "Oct", "Nov", "Dec"
		];
		
		// Extract date components
		const day = date.getDate();
		const month = months[date.getMonth()];
		const year = date.getFullYear();
		
		// Format the date as "Month Day, Year"
		const formattedDate = `${month} ${day}, ${year}`;
		
		return formattedDate;
	}
  formatNoteDate = (dateString: any): React.ReactNode => {
    const date = new Date(dateString);
    const months = [
			"Jan", "Feb", "Mar", "Apr",
			"May", "Jun", "Jul", "Aug",
			"Sep", "Oct", "Nov", "Dec"
		];
    const day = date.getDate();
		const month = months[date.getMonth()];

    const timePart = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
    }).format(date);
    
    return `${day} ${month} ${date.getFullYear()} ${timePart.toLowerCase()}`;
  };
  deleteContactEvent = () => {
    this.setState({
      isDeleteContact: true,
      delContactDetails: {
        firstName: this.state.contactDetails.first_name,
        lastName: this.state.contactDetails.last_name,
        id: this.state.contactDetails.id,
      },
      delModalOpen: true
    });
  };

  closeDeleteModal = () => {
    this.setState({
      isDeleteContact: false,
      delModalOpen: false,
      delContactDetails: {
        firstName: "",
        lastName: "",
        id: "",
      },
    });
  }

  deleteContactAction = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteContactAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewContactsPath}/${this.state.contactDetails.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  viewEditDataAction = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    let endPoint = `${configJSON.contactsPath}/${this.state.contactDetails.id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactlistDataApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAllNotes = () => {
    const header = {
      token: this.userToken.meta.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotesApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notesEndpoint}?contact_id=${this.state.selectedContactId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createNotes = () => {
    const header = {
      token: this.userToken.meta.token,
      'Content-Type': 'application/json',
    };
    const body={
      contact_id: this.state.contactDetails.id,
      note: {
          content: this.state.notes
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNotesApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteNoteAction = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endpoint = `${configJSON.notesEndpoint}/${this.state.noteViewId}?contact_id=${this.state.contactDetails.id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteNoteAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    this.setState({
      isLoading : true
    })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateNoteAction = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };

    let endpoint = `${configJSON.notesEndpoint}/${this.state.noteUpdateId}`;
    const body ={
      "contact_id": this.state.contactDetails.id,
      "note": {
          "content": this.state.selectedNote
      }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateNoteAPI = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMergeContactOpen=()=>{
    this.setState({isMergeContact:true})
    this.setState({
      isSearching:false,
      isSortAndFiltering:false,
      sortValue:"",
      searchKeyword:"" ,
      filterShippingCountry:"",
      filterCompany:"",
      filterBusinessCategories:"",
      filterGroups:"",
      hasAnOpenDeal: false,
      hasInteractions: false,
      hasFavoritesProduct: false,
      hasNotes: false,
     })
     this.getContactsList()
  }
  
  handleOpenDeal = (
    event: React.MouseEvent<HTMLButtonElement> | null
  ) => {
    localStorage.removeItem("isInteraction");
    if (this.state.createDealPopoverShown) {
      return;
    }
    if (event) {
      this.setState({
        anchorElDeal: event.currentTarget,
        createDealPopoverShown: true,
      });
    }
  };

  handleCloseDeal = () => {
    this.setState({ anchorElDeal: null,  createDealPopoverShown: false});
    const isInteraction= localStorage.getItem("isInteraction");
    if(isInteraction==="true"){
      this.getContactDetails(this.state.viewContactId)
    }
  };
  handleSelectContactsOpen=()=>{
    this.setState({selectContacts:true})
  }
  handleSelectContactsClose=()=>{
    this.setState({selectContacts:false})
  }
  chooseStageHandlerEvent = (dealId: string, dealStage: string, dealNote: string) => {
    this.setState({ isActiveChooseStage: true });
    let dealStausValue;
    if (dealStage === "Initiated") {
      dealStausValue = 0;
    } else if (dealStage === "sent proposal") {
      dealStausValue = 1;
    } else if (dealStage === "follow up") {
      dealStausValue = 2;
    } else if (dealStage === "won") {
      dealStausValue = 3;
    } else if (dealStage === "lost") {
      dealStausValue = 4;
    }   
    this.setState({
      currentDealStage: {
        currentdealStage: dealStausValue,
        currentdealId: dealId,
      },
      addDealNote: dealNote,
      newStatus : dealStausValue,
    });
  };
  dealNoteHandlerEvent = (dealNote: string) => {
    this.setState({ addDealNote: dealNote });
  };
  handleStageChangeEvent = (event: any) => {
    if (event.target.value != undefined) {
      this.setState({ newStatus: event.target.value });
    }
  };
  chooseStageModalCloseEvent = () => {
    this.setState({ isActiveChooseStage: false });
  };
  updateDealStageEvent = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const { newStatus, currentDealStage, addDealNote } = this.state;

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateDealStatusRequestId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateDealStatus +
        `id=${currentDealStage.currentdealId}&stage=${newStatus}&note=${addDealNote}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPATCH
    );

    this.setState({ isLoading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  countryListDataAPICallEvent = () => {
    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.countryListDataApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.webGetCountryList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodGET
    );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
     );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleOpenGroup=()=>{
    localStorage.removeItem("contactIds")
    this.setState({
      openGroup: true,
    });
  }

  handleCloseGroup=()=>{
    this.setState({openGroup:false})
  }
  toggleMenuDrawer = () => {      
    this.setState({
        isMenuDrawerOpened: true,
    })        
}
  closeMenuDrawer = () => {
      this.setState({
          isMenuDrawerOpened: false,
      })
  }
  handleBackBtn = () => {
    window.history.back();
  };
  handleRemoveProduct = (id:any) => {
    this.setState({undoFavProductId:id})
    const header = {
      "Content-Type": "application/json",
      token: this.userToken.meta.token,
    };
    const body={
      contact_id: this.state.contactDetails.id,
      product_id: id
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteContactFavProductAPIID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeFavProductContactsPath}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodDELETE
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOpenFavProduct=()=>{
    this.setState({
      favProduct: true,
    });
  }
  
  handleCloseFavPRoduct=()=>{
    this.setState({favProduct:false})
  }
  handleSortChange = (event: any) => {
    this.setState({sortValueFavPRoduct:event.target.value as string});
  };
  handleSortChangeNotes = (event: any) => {
    this.setState({sortValueNotes:event.target.value as string});
  };
  handleSortChangeDeals = (event: any) => {
    this.setState({sortValueDeals:event.target.value as string});
  };

  addAsFavProduct = (productsSelected:any,productQuantities:any) => {
    const formattedProducts = productsSelected.map((productId:any, index:any) => {
      const quantity = productQuantities[productId];
  
      return {
          id: productId , 
          quantity: quantity ?? 1,
          price: {
            price_id: 1,
            amount: 0
          }
      };
  });

    const header = {
      token: this.userToken.meta.token,
      "Content-Type": "application/json",
    };
    const body={
      "contact": {
          "contact_products": {
              "products": formattedProducts,
          }
      }
   }

    const requestMessage: Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAsFavProductApiRequestId = requestMessage.messageId;  

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addAsFavPRoductAPiEndpoint}/${this.state.contactDetails.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodPOST
    );
    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


}
// Customizable Area End
